import html2pdf from 'html2pdf.js';
import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import Barcode from 'react-barcode';
import { Layout } from '../../../components/layout/Index';
import { Requests } from '../../../utils/Http/Index';

const PrintBarcode = ({ items, appInfo }) => {
  const [generateWithPrice, setGenerateWithPrice] = useState(false);
  const [generateWithExp, setGenerateWithExp] = useState(false);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const DOKAN_NAME = localStorage.getItem('dokanname');
  const [form, setForm] = useState({
    product: '',
    paperSize: 0,
    quantity: 10,
  });
  const [showBarcode, setShowBarcode] = useState(false);
  const [pages, setPages] = useState([]);
  const [perPage, setPerPage] = useState('per-page-40');

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await Requests.Inventory.Product.DokanProductList(0, 0);
      const products = response.data.data.map((product) => {
        return { label: product.name, value: product.uid, ...product };
      });
      setLoading(false);
      setProducts(products);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const updateClassName = () => {
    let hasError = false;

    if (!form.product) {
      alert('This product field is required');
      hasError = true;
    }
    if (!form.paperSize) {
      alert('This paper size field is required');
      hasError = true;
    }

    if (!hasError) {
      setPages([]);
      setPerPage(`per-page-${form.paperSize}`);
      const totalPages = Math.ceil(form.quantity / form.paperSize);
      let remainingQuantity = form.quantity;

      const newPages = [];
      for (let page = 0; page < totalPages; page++) {
        newPages.push(
          remainingQuantity > form.paperSize
            ? form.paperSize
            : remainingQuantity
        );
        remainingQuantity -= form.paperSize;
      }
      setPages(newPages);
    }
  };

  const generateBarcode = () => {
    updateClassName();
    setShowBarcode(true);
  };

  const print = () => {
    // Check if the paper size is 1 (i.e., 1 barcode per sheet)
    if (form.paperSize === '1') {
      // Open a new window to print the barcodes
      const printWindow = window.open('', '', 'height=800,width=1200');

      // Get the content for printing (only the barcode section)
      const printContent = document.querySelector('.barcode-wrapper').innerHTML;

      // Write the content and styles into the new print window
      printWindow.document.write('<html><head><title>Print Barcodes</title>');
      printWindow.document.write('<style>');
      printWindow.document.write(`
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      text-align: center;
    }
    .barcode-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);  /* Set 4 columns */
      gap: 4px; /* Add space between columns */
       justify-items: start; /* Align items to the start horizontally */
    align-items: start; /* Align items to the start vertically */
      margin: 0 auto;
      width: 100%;
      padding: 10px;
    }
      .price-tag {
  transform: rotate(-90deg); /* Rotate the price tag 90 degrees */
  transform-origin: left center; /* Adjust the rotation origin */
  position: absolute; /* Make the price tag float beside the barcode */
  top: 40px;
  left: -20px; /* Position it to the left of the barcode */
  font-size: 8px; /* Adjust the font size as needed */
  white-space: nowrap; /* Ensure text doesn't wrap */
}
.exp-tag {
  transform: rotate(-90deg); /* Rotate the price tag 90 degrees */
  transform-origin: right center; /* Adjust the rotation origin */
  position: absolute; /* Make the price tag float beside the barcode */
  bottom: 40px;
  right: -20px; /* Position it to the left of the barcode */
  font-size: 8px; /* Adjust the font size as needed */
  white-space: nowrap; /* Ensure text doesn't wrap */
}
    .barcode-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      text-align: center;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      padding: 1px 0;
      margin: 0px;
      width: 120px;  /* Set a fixed width for each barcode item */
    }
    .print-page-layout {
      margin: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      align-items: left;
    }
      .barcode-code{
        position: relative;}
    @media print {
      body {
        margin: 0;
        padding: 0;
      }
      .barcode-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);  /* Ensure 4 columns during printing */
        gap: 10px;  /* Adjust space between columns */
        padding: 2px 0px;
        width: 100%;
        margin: 0 auto;
      }
      .barcode-item {
        width: 100%;  /* Ensure it fills the grid cell */
        max-width: 200px;  /* Prevent shrinking too much */
        font-size: 7px;  /* Increase font size if necessary */
      }
      .no-print {
        display: none;
      }
    }
  `);
      printWindow.document.write('</style>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(printContent);
      printWindow.document.write('</body></html>');

      // After content is loaded, trigger the print
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    } else {
      // If it's not 1 per sheet, use html2pdf.js to generate and print the PDF
      const barcodeWrapper = document.querySelector('.barcode-wrapper');

      // Define options for html2pdf
      const options = {
        margin: 2,
        filename: 'barcodes.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      // Use html2pdf.js to generate and print the PDF
      html2pdf()
        .from(barcodeWrapper) // Select the barcode-wrapper div
        .set(options) // Apply options for layout, format, etc.
        .toPdf()
        .get('pdf')
        .then(function (pdf) {
          pdf.autoPrint(); // Trigger auto print dialog
          window.open(pdf.output('bloburl'), '_blank'); // Open in new tab
        });
    }
  };

  const handleChange = (field) => (event) => {
    const value = field === 'product' ? event : event.target.value;
    if (field === 'product') {
      const selectedProduct = products.find(
        (product) => product.value === event.value
      );
      if (selectedProduct) {
        if (selectedProduct.is_code_separate === 1) {
          setForm((prevForm) => ({
            ...prevForm,
            [field]: selectedProduct,
            codes: selectedProduct.codes || [],
            quantity: 1,
          }));
        } else {
          setForm((prevForm) => ({
            ...prevForm,
            [field]: selectedProduct,
            codes: [],
          }));
        }
      }
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [field]: value,
      }));
    }
  };

  const handlePriceToggle = () => {
    setGenerateWithPrice(!generateWithPrice);
  };
  const handleExpToggle = () => {
    setGenerateWithExp(!generateWithExp);
  };

  return (
    <div>
      <Layout
        page='product / print barcode'
        message={'print barcodes for your products.'}
        container='container-fluid'
      />
      <div className='card no-print'>
        <div className='card-header'>
          <h3>Generate Barcode</h3>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            generateBarcode();
          }}
        >
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-md-4'>
                <label htmlFor='product'>Select Products *</label>
                <Select
                  options={products}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className={form.product ? '' : 'is-invalid'}
                  onChange={handleChange('product')}
                  placeholder='Search products'
                />
              </div>
              <div className='form-group col-md-4'>
                <label htmlFor='paperSize'>Paper size</label>
                <select
                  id='paperSize'
                  value={form.paperSize}
                  onChange={handleChange('paperSize')}
                  className={`form-control ${
                    form.paperSize ? '' : 'is-invalid'
                  }`}
                >
                  <option disabled value='0'>
                    Paper size
                  </option>
                  <option value='40'>40 per sheet (a4) (1.799 * 1.003)</option>
                  <option value='30'>30 per sheet (2.625 * 1)</option>
                  <option value='24'>24 per sheet (a4) (2.48 * 1.334)</option>
                  <option value='20'>20 per sheet (4 * 1)</option>
                  <option value='18'>18 per sheet (a4) (2.5 * 1.835)</option>
                  <option value='14'>14 per sheet (4 * 1.33)</option>
                  <option value='12'>12 per sheet (a4) (2.5 * 2.834)</option>
                  <option value='10'>10 per sheet (4 * 2)</option>
                  <option value='1'>1 per sheet (Xprinter 365B)</option>
                </select>
              </div>
              {form.product && form.product.is_code_separate !== 1 && (
                <div className='form-group col-md-4'>
                  <label htmlFor='quantity'>Quantity *</label>
                  <input
                    type='number'
                    value={form.quantity}
                    onChange={handleChange('quantity')}
                    className='form-control'
                    id='quantity'
                    min='1'
                    max='1000'
                  />
                </div>
              )}
              <div className='form-group col-md-12'>
                <input
                  type='checkbox'
                  checked={generateWithPrice}
                  onChange={handlePriceToggle}
                  id='showPrice'
                />
                <label htmlFor='showPrice' className='ml-2'>
                  Show Product Price in Barcode
                </label>
              </div>
              <div className='form-group col-md-12'>
                <input
                  type='checkbox'
                  checked={generateWithExp}
                  onChange={handleExpToggle}
                  id='showExp'
                />
                <label htmlFor='showExp' className='ml-2'>
                  Show Expire Date in Barcode
                </label>
              </div>
            </div>
          </div>

          <div className='card-footer'>
            <button type='submit' className='btn btn-primary'>
              <i className='fas fa-edit' /> Generate Barcode
            </button>
          </div>
        </form>
      </div>

      {/* Barcode Display */}
      {showBarcode && (
        <div className='card'>
          <div className='card-header'>
            <h3>Print Barcode</h3>
            <button onClick={print} className='btn btn-info float-right'>
              <i className='fas fa-print' /> Print
            </button>
          </div>
          <div className='card-body'>
            <div className='barcode-wrapper '>
              {/* Barcode generation for products with separate codes */}
              {form.product && form.product.is_code_separate === 1
                ? pages.map((pageNumber, i) => (
                    <div
                      key={i}
                      className={`print-page-layout display-grid ${perPage}`}
                    >
                      {Array.from({ length: pageNumber }).map((_, j) => {
                        const codeObj = form.codes[i * form.paperSize + j];
                        return (
                          codeObj && (
                            <div key={j} className='barcode-item'>
                              <strong className='barcode-name fs-6 py-1'>
                                {DOKAN_NAME}
                              </strong>
                              <span className='barcode-name'>
                                {form.product.label}
                              </span>
                              <Barcode
                                value={codeObj.code}
                                width={1}
                                height={20}
                                fontSize={15}
                              />
                              {generateWithPrice && (
                                <span className='barcode-name  pb-1'>
                                  Price: {codeObj.selling_price} TK.
                                </span>
                              )}
                            </div>
                          )
                        );
                      })}
                    </div>
                  ))
                : form.product &&
                  form.product.is_code_separate === 0 &&
                  pages.map((pageNumber, i) => (
                    <div
                      key={i}
                      className={`print-page-layout display-grid ${perPage}`}
                    >
                      {Array.from({ length: pageNumber }).map((_, j) => (
                        <div key={j} className='barcode-item py-2'>
                          {form.product.product_code ? (
                            <>
                              <strong className='barcode-name fs-6 fw-bold '>
                                {DOKAN_NAME}
                              </strong>
                              <span className='barcode-name fs-6'>
                                {form.product.label}
                              </span>

                              <div className='barcode-code position-relative'>
                                <Barcode
                                  value={form.product.product_code || ''}
                                  width={1}
                                  height={20}
                                  fontSize={12}
                                />
                                {generateWithExp && form.product.exp_date && (
                                  <span className='barcode-name exp-tag'>
                                    Exp: {form.product.exp_date}
                                  </span>
                                )}
                              </div>
                              {generateWithPrice && (
                                <span className='barcode-name '>
                                  Price: {form.product.selling_price} TK.
                                </span>
                              )}
                            </>
                          ) : (
                            <span className='barcode-name'>
                              No product code available
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintBarcode;
