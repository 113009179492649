import axiosInstance from './axiosInterceptor';
import { API } from '../Api';

const CreatePos = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.post(
    `${API}/orders?dokan_uid=${localStorage.getItem('dokanuid')}`,
    data,
    config
  );
};

const GenerateInvoice = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.post(
    `${API}/order-invoice-number/${localStorage.getItem('dokanuid')}`,
    {},
    config
  );
};

const checkProductReturn = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.get(
    `${API}/returnable-product-list/${data}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};
// const getInvoicePdf = async (orderId) => {
//   console.log(orderId, 'orderId');

//   const config = {
//     headers: {
//       'Content-Type': 'application/pdf',
//       Accept: 'application/pdf',
//       Authorization: `Bearer ${localStorage.getItem('token')}`,
//     },
//   };

//   return axiosInstance.get(`${API}/pos-order-pdf/${orderId}`, config);
// };
// const getInvoicePdf = async (orderId) => {
//   console.log(orderId, 'orderId');
//   // "Accept": "/",
//   //         "Accept-Encoding": "gzip, deflate, br",
//   //         "Connection": "keep-alive",
//   const config = {
//     headers: {
//       'Content-Type': 'application/pdf',
//       Accept: 'application/pdf',
//       Authorization: `Bearer ${localStorage.getItem('token')}`,
//     },
//     responseType: 'arraybuffer', // Ensure binary response for PDF
//   };

//   try {
//     const response = await axiosInstance.get(
//       `${API}/pos-order-pdf/${orderId}`,
//       config
//     );
//     console.log(response, 'response');
//     // Convert the response to base64
//     const base64Data = Buffer.from(response.data, 'binary').toString('base64');
//     return base64Data;
//   } catch (error) {
//     console.error('Error fetching PDF:', error);
//     throw error;
//   }
// };
const getInvoicePdf = async (orderId) => {
  console.log(orderId, 'orderId');

  // const config = {
  //   headers: {
  //     'Content-Type': 'application/pdf',
  //     Accept: '/',
  //     'Accept-Encoding': 'gzip, deflate, br',
  //     Connection: 'keep-alive',
  //     Authorization: `Bearer ${localStorage.getItem('token')}`,
  //   },
  //   responseType: 'arraybuffer', // Ensure binary response for PDF
  // };

  const config = {
    headers: {
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    responseType: 'arraybuffer', // Ensure binary response for PDF
  };

  try {
    const response = await axiosInstance.get(
      `${API}/pos-order-pdf/${orderId}`,
      config
    );
    console.log(response, 'response');

    // Convert the response data (arraybuffer) to a Blob
    const blob = new Blob([response.data], { type: 'application/pdf' });

    console.log(blob, 'blob');

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(blob);

    // Optionally, open the PDF in a new window/tab
    // window.open(pdfUrl);

    // If you want to download the PDF instead:
    // const link = document.createElement('a');
    // link.href = pdfUrl;
    // link.download = `invoice_${orderId}.pdf`; // or any desired file name
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    console.log(pdfUrl, 'pdfurl');
    return pdfUrl; // Return the URL if you want to use it later
  } catch (error) {
    console.error('Error fetching PDF:', error);
    throw error;
  }
};

const OrderDelete = async (uid) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.delete(
    `${API}/orders/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const OrderSearch = async (
  search,
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/orders?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
  );
};

// for all customer list
const OrderList = async (
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/orders?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
  );
};

const RevenueList = async (
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/revenue-report?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
  );
};

const RevenueSearch = async (
  search,
  page = 1,
  perpage = 10,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/revenue-report?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
  );
};

const POS = {
  getInvoicePdf,
  RevenueSearch,
  RevenueList,
  CreatePos,
  OrderSearch,
  OrderList,
  OrderDelete,
  GenerateInvoice,
  checkProductReturn,
};

export default POS;
