import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormGroup } from '../../../components/formGroup/FormGroup';
import { Text } from '../../../components/text/Text';
import { PrimaryButton } from '../../../components/button/Index';
import { Box, CreditCard, ShoppingCart } from 'react-feather';
import { Container } from '../../../components/container/Index';
import { Form } from 'react-bootstrap';
import { InputGroup } from '../../../components/inputGroup/Index';

const POSPage = () => {
  // State for managing cart items, customer selection, and discount
  const [cartItems, setCartItems] = useState([
    { id: 1, name: 'Product 1', price: 10, quantity: 1 },
    { id: 2, name: 'Product 2', price: 20, quantity: 1 },
  ]);
  const [customer, setCustomer] = useState('');
  const [discount, setDiscount] = useState(0);

  // Sample product data
  const products = [
    {
      id: 1,
      name: 'Product 1',
      price: 10,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 2,
      name: 'Product 2',
      price: 20,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 30,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 30,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 30,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 30,
      image: 'https://via.placeholder.com/50',
    },
    {
      id: 3,
      name: 'Product 3',
      price: 30,
      image: 'https://via.placeholder.com/50',
    },
  ];

  // Function to handle adding products to the cart
  const addToCart = (product) => {
    setCartItems([...cartItems, { ...product, quantity: 1 }]);
  };

  // Function to handle quantity change
  const adjustQuantity = (id, quantity) => {
    setCartItems(
      cartItems.map((item) => (item.id === id ? { ...item, quantity } : item))
    );
  };

  const handleAddProductByScan = (event) => {
    event.preventDefault();
    console.log('Product scanned or submitted');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted');
  };

  // Calculate total price after discount
  const total = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );
  const totalAfterDiscount = total - total * (discount / 100);

  return (
    <div className='container-fluid p-4'>
      <div className='row'>
        {/* Left Section: Product Search and Product Cards */}
        <div className='col-md-8'>
          {/* Search Bar */}
          <div className='mb-3 search-bar'>
            <input
              type='text'
              className='form-control'
              placeholder='Search for a product'
            />
          </div>

          {/* Product Cards */}
          <div className='row'>
            {products.map((product) => (
              <div className='col-md-3  mb-4 product-card' key={product.id}>
                <div className='card'>
                  <div className='p-2'>
                    <Container.Row>
                      <Container.Column className='col-md-12 '>
                        {' '}
                        <img
                          src={product.image}
                          style={{ height: '120px', objectFit: 'cover' }}
                          className='card-img-top'
                          alt={product.name}
                        />
                      </Container.Column>
                      {/* <Container.Column className='col-md-6'>
                        <div className='d-flex flex-column justify-content-center '>
                          <span className='px-2'> 100 </span>
                          <span className='px-2'> In stock </span>
                        </div>
                      </Container.Column> */}
                    </Container.Row>

                    <h6 className='card-title pt-2'>{product.name}</h6>
                    <p className='card-text'>${product.price}</p>
                    <div className='d-flex justify-content-between'>
                      <button
                        className='btn btn-light  '
                        onClick={() => addToCart(product)}
                      >
                        <Box size={20} />
                        <span className='px-2'> 100</span>
                      </button>
                      <button
                        className='btn btn-warning  '
                        onClick={() => addToCart(product)}
                      >
                        <ShoppingCart size={20} />
                        <span className='px-2'> ADD</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right Section: Cart */}
        <div className='col-md-4'>
          <div className='card p-3 bg-light'>
            <Container.Fluid>
              <Container.Row>
                <Container.Column className='col-lg-12'>
                  {/* Scan Product Form */}
                  <form onSubmit={handleAddProductByScan}>
                    <div className='pt-1 mb-2'>
                      <FormGroup>
                        <Text className='font-weight-normal fs-14 m-0'>
                          Scan Product Code/IMEI
                        </Text>
                        <input
                          type='text'
                          className='form-control shadow-none'
                          placeholder='Scan Product Code/IMEI'
                        />
                      </FormGroup>
                    </div>
                  </form>
                </Container.Column>
              </Container.Row>

              <Container.Row>
                <Container.Column className='col-lg-12'>
                  <Text className='fs-13 mb-0'>
                    Customer <span className='text-danger'>*</span>
                    <span
                      className='brand-add'
                      onClick={() => console.log('Add Customer modal opened')}
                    >
                      {' '}
                      / Add Customer
                    </span>
                  </Text>

                  <div className='mt-2'>
                    <Text className='fs-14 mb-0 borderless'>Products</Text>
                    <div>{/* hi */}</div>
                  </div>

                  {/* Discount Amount */}
                  <div className='pt-2'>
                    <Text className='font-weight-normal fs-14 m-0'>
                      Discount Amount
                    </Text>
                    <InputGroup append='Tk'>
                      <input
                        type='text'
                        className='form-control shadow-none'
                        placeholder='Discount Amount'
                      />
                    </InputGroup>
                  </div>

                  {/* Previous Due */}
                  <div className='d-flex justify-content-between pt-2'>
                    <Text className='fs-14 mb-0'>Previous Due: 0 Tk</Text>
                  </div>
                  <div className='pt-0'>
                    <Form.Check
                      custom
                      type='checkbox'
                      id='custom-3'
                      label='Do you want add previous due?'
                      style={{ fontSize: 14, paddingTop: '2px' }}
                    />
                  </div>

                  <hr />
                  <Container.Row>
                    <div className='d-flex justify-content-between col-12'>
                      <Text className='fs-14 mb-0'>Total Quantity:</Text>
                      <Text className='fs-14 mb-0'>0</Text>
                    </div>

                    <div className='d-flex justify-content-between col-12'>
                      <Text className='fs-14 mb-0'>Discount Amount:</Text>
                      <Text className='fs-14 mb-0'>(-) 0 Tk</Text>
                    </div>
                  </Container.Row>
                  <hr />

                  {/* Total Price */}
                  <Container.Row>
                    <div className='d-flex justify-content-between col-12'>
                      <Text className='fs-14 mb-0 font-weight-bold'>
                        Total Price:
                      </Text>
                      <Text className='fs-14 mb-0 font-weight-bold'>0 Tk</Text>
                    </div>
                  </Container.Row>
                  <hr />
                  <Container.Row>
                    <div className='col-md-6'>
                      <Text className='font-weight-normal fs-14 m-0'>
                        Amount To Pay <span className='text-danger'>*</span>
                      </Text>
                      <InputGroup append='Tk'>
                        <input
                          type='number'
                          className='form-control shadow-none'
                          required
                          placeholder='Amount To Pay'
                        />
                      </InputGroup>
                    </div>
                    <div className='col-md-6'>
                      <Text className='font-weight-normal fs-14 m-0'>
                        Payment Option <span className='text-danger'>*</span>
                      </Text>
                      <FormGroup className='mb-0'>
                        <select
                          className='form-control shadow-none'
                          name='paymentoption'
                          required
                        >
                          <option value='cash'>Cash</option>
                          <option value='card'>Card</option>
                          <option value='online'>Online Payment</option>
                        </select>
                      </FormGroup>
                    </div>
                  </Container.Row>
                  {/* Amount to Pay */}

                  {/* Payment Option */}

                  {/* Note */}
                  <FormGroup>
                    <Text className='fs-13 mb-0'>Note</Text>
                    <textarea
                      rows={3}
                      className='form-control shadow-none'
                      placeholder='Enter Note'
                    />
                  </FormGroup>
                  <hr />

                  {/* Submit Button */}
                  <div className='text-right'>
                    <PrimaryButton
                      type='submit'
                      className='px-4'
                      onClick={handleSubmit}
                    >
                      <span>
                        <CreditCard size={18} /> Process Order
                      </span>
                    </PrimaryButton>
                  </div>
                </Container.Column>
              </Container.Row>
            </Container.Fluid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POSPage;
